<template>
    <div>
       <b-row class="computadora">
          <b-col class="p">
            <img class="mb-3 resp-img" src="../assets/img/img_collage1.png"  alt="img collage">
            <img class="resp-img" src="../assets/img/img_collage2.png"  alt="img collage">
          </b-col>
          <b-col class="h">
            <img class="resp-img2 uno" src="../assets/img/img_collage3.png"  alt="img collage">
          </b-col>
          <b-col class="o">
            <img class="resp-img2 dos" src="../assets/img/img_collage4.png"  alt="img collage">
          </b-col>
          <b-col class="n">
            <img   class="mb-3 resp-img3" src="../assets/img/img_collage5.png"  alt="img collage">
            <img class="resp-img3" src="../assets/img/img_collage6.png"  alt="img collage">
          </b-col>
       </b-row>
       <div class="image-loop telefono">
      <div class="image-container">
        <img src="../assets/img/img_collage1.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage2.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage3.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage4.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage5.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage6.png" class="loop-image"  alt="img collage">

        <img src="../assets/img/img_collage1.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage2.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage3.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage4.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage5.png" class="loop-image"  alt="img collage">
        <img src="../assets/img/img_collage6.png" class="loop-image"  alt="img collage">
      </div>
 
        </div>
 

    </div>  
 

</template>

<script>
export default {
 
};
</script>


<style scoped>
 
.telefono{
  display: none;
}
.resp-img{
  width: 100%;
  height: 44% !important;
 
}
.resp-img2{
  width: 105%;
 
  height: auto;
}

.resp-img3{
  width: 101%;
 
  height: auto;
}
.image-loop {
 
  overflow: hidden;
  white-space: nowrap;
  display: flex; 
  align-items: center;
  justify-content: flex-start; 
  padding-bottom: 2%;


}

.telefono{
  display: none;
}
.image-container {
 
  display: flex; 
  flex-direction: row; 
  animation: loop  25s linear infinite; 
  align-items: center;
}
.loop-image {
  width:240px;
  max-height: 300px;
  height: auto;
  margin-right: 20px;
  border-radius: 20px;
}
 

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50%));
  }
}


@media (max-width: 768px) {
  .resp-img{
  width: 150%;
  height: 46% !important;
    margin-left: -99%;
}

.telefono{
  display: flex;
}

.computadora{
  display: none;
}
.resp-img2{
  width: 170%;

  height: auto;
}

.uno{
  margin-left: -60%;
}

 

.resp-img3{
  width: 160%;
  height:  auto;
 
    position: relative;
    right: -58%;
}

}

@media (max-width: 500px) {
  .resp-img{
  width: 150%;
  height: 46% !important;
  position: relative;
    left:-10%;
}
.resp-img2{
  width: 220%;

  height: auto;
}

.uno{
  margin-left: -90%;
}

 

.resp-img3{
  width: 202%;
  height:  auto;
 
    position: relative;
    right: -88%;
}


}
</style>