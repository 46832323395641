<template>
    <div id="soluciones">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="7">
            <h3>{{ translations.solutions.title }}</h3>
          </b-col>
        </b-row>
      </b-container>
      <b-row>
        <carousel id="carousel-soluciones"
          controls
          indicators
          background="#01146E"
          interval="4000"
          perPage="2.5"
          class="desk"
          style="width: 100%;">

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services1.svg" class="img-card"/>
          <h4 class="mb-4">{{ translations.solutions.carousel[0].title }}</h4>
          <p v-for="service in translations.solutions.carousel[0].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services2.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[1].title }}</h4>
          <p v-for="service in translations.solutions.carousel[1].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services3.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[2].title }}</h4>
          <p v-for="service in translations.solutions.carousel[2].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
          <a href="https://www.realestatelab.com.mx/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services4.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[3].title }}</h4>
          <p v-for="service in translations.solutions.carousel[3].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
          <a href="https://www.impulsopolitico.com/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

</carousel>

<carousel id="carousel-soluciones"
          controls
          indicators
          background="#01146E"
          interval="4000"
          perPage="2"
          class="phone"
          style="width: 100%;">

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services1.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[0].title }}</h4>
          <p v-for="service in translations.solutions.carousel[0].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center right">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services2.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[1].title }}</h4>
          <p v-for="service in translations.solutions.carousel[1].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services3.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[2].title }}</h4>
          <p v-for="service in translations.solutions.carousel[2].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
          <a href="https://www.realestatelab.com.mx/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center right">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services4.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[3].title }}</h4>
          <p v-for="service in translations.solutions.carousel[3].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
          <a href="https://www.impulsopolitico.com/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

</carousel>

<carousel id="carousel-soluciones"
          controls
          indicators
          background="#01146E"
          interval="4000"
          perPage="2"
          class="phone2 pt-2"
          style="width: 100%;">

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services1.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[0].title }}</h4>
          <p v-for="service in translations.solutions.carousel[0].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center right">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services2.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[1].title }}</h4>
          <p v-for="service in translations.solutions.carousel[1].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services3.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[2].title }}</h4>
          <p v-for="service in translations.solutions.carousel[2].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
          </p>
          <a href="https://www.realestatelab.com.mx/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

  <slide>
    <b-row class="justify-content-center right">
      <div class="service-card">
        <b-col md="12" class="text-left off">
          <img alt="services" src="../assets/img/icn_services4.svg" class="img-card" />
          <h4 class="mb-4">{{ translations.solutions.carousel[3].title }}</h4>
          <p v-for="service in translations.solutions.carousel[3].services" :key="service">
            <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service

 }}
          </p>
          <a href="https://www.impulsopolitico.com/" target="_blank">
            <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
          </a>
        </b-col>
      </div>
    </b-row>
  </slide>

</carousel>

        <carousel id="carousel-soluciones"
            ref="carousel"
            :centerMode="true"
            :loop="true"
            background="#01146E"
            :interval="4000"
            :paginationEnabled="false"
            :navigationEnabled="true"
            :navigationNextLabel="'' "
            :navigationPrevLabel="''"
            :perPage="1"
            class="phone3 pt-2"
            style="width: 100%;">
    
    <slide>
      <b-row class="justify-content-center">
        <div class="service-card">
          <b-col md="12" class="text-left off">
            <img alt="services" src="../assets/img/icn_services1.svg" class="img-card" />
            <h4 class="mb-4">{{ translations.solutions.carousel[0].title }}</h4>
            <p v-for="service in translations.solutions.carousel[0].services" :key="service">
              <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
            </p>
          </b-col>
        </div>
      </b-row>
 
    </slide>
    <slide>

      <b-row class="justify-content-center right">
        <div class="service-card">
          <b-col md="12" class="text-left off">
            <img alt="services" src="../assets/img/icn_services2.svg" class="img-card" />
            <h4 class="mb-4">{{ translations.solutions.carousel[1].title }}</h4>
            <p v-for="service in translations.solutions.carousel[1].services" :key="service">
              <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
            </p>
          </b-col>
        </div>
      </b-row>

    </slide>
 

    <slide>
      <b-row class="justify-content-center">
        <div class="service-card">
          <b-col md="12" class="text-left off">
            <img alt="services" src="../assets/img/icn_services3.svg" class="img-card" />
            <h4 class="mb-4">{{ translations.solutions.carousel[2].title }}</h4>
            <p v-for="service in translations.solutions.carousel[2].services" :key="service">
              <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
            </p>
            <a href="https://www.realestatelab.com.mx/" target="_blank">
              <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
            </a>
          </b-col>
        </div>
      </b-row>      
    </slide>
    <slide>
      <b-row class="justify-content-center right">
        <div class="service-card">
          <b-col md="12" class="text-left off">
            <img alt="services" src="../assets/img/icn_services4.svg" class="img-card" />
            <h4 class="mb-4">{{ translations.solutions.carousel[3].title }}</h4>
            <p v-for="service in translations.solutions.carousel[3].services" :key="service">
              <img src="../assets/img/bullets.png" alt="bullets" style="margin-right: 10px;" />{{ service }}
            </p>
            <a href="https://www.impulsopolitico.com/" target="_blank">
              <b-button class="btn-inicio">{{ translations.menu.learn }}</b-button>
            </a>
          </b-col>
        </div>
      </b-row>
    </slide>

 
  </carousel>
 
 
      </b-row>
    </div>
  </template>
 
 <script>
 
 
 import { Carousel, Slide } from 'vue-carousel';
 import esp from '../assets/json/es.json';
 import en from '../assets/json/en.json';
 
 export default {
   props: {
     activeLanguage: {
       type: String,
       required: true
     }
   },
   components: {
     Carousel,
     Slide 
   },
   data() {
     return {
      translations: this.activeLanguage === 'eng' ? en : esp,
       currentSlide: 0
     }
   },
   watch: {
     activeLanguage(newLanguage) {
      this.translations = newLanguage === 'eng' ? en : esp;
     }
   },
   mounted() {
     this.updateNavigationClasses();
     this.$refs.carousel.$on('after-slide', this.updateNavigationClasses);
   },
   methods: {
     updateNavigationClasses() {
       console.log('Updating navigation classes');
       const slides = this.translations.solutions.carousel.length;
       this.currentSlide = this.$refs.carousel.currentPage;
       console.log('Current slide:', this.currentSlide);
 
       // Reset classes
       const dots = document.querySelectorAll('.VueCarousel-dot');
       if (!dots.length) {
         console.warn('No dots found');
         return;
       }
       dots.forEach((dot) => {
         dot.classList.remove('VueCarousel-dot-prev', 'VueCarousel-dot-next');
       });
 
       // Log the dots to see if they are being selected
       console.log('Dots:', dots);
 
       // Set prev and next classes
       if (this.currentSlide > 0) {
         const prevIndex = this.currentSlide - 1;
         if (dots[prevIndex]) {
           dots[prevIndex].classList.add('VueCarousel-dot-prev');
         }
       }
       if (this.currentSlide < slides - 1) {
         const nextIndex = this.currentSlide + 1;
         if (dots[nextIndex]) {
           dots[nextIndex].classList.add('VueCarousel-dot-next');
         }
       }
 
       // Log the classes after setting them
       dots.forEach((dot, index) => {
         console.log(`Dot ${index} classes:`, dot.className);
       });
     }
   }
 }
 </script>
 
<style scoped>

.VueCarousel-navigation-button {
  position: absolute;
  bottom: 0%;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}



/* Ocultar todos los botones de paginación */
.VueCarousel-dot {
  display: none;
}

/* Mostrar solo los botones de navegación anterior y siguiente */
.VueCarousel-dot-active,
.VueCarousel-dot-prev,
.VueCarousel-dot-next {
  display: block;
}
h3{
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}

h4{
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;


}
 
p{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    margin-bottom: 0 !important;

}
#soluciones{
    background-image: url('../assets/img/BG.png');
    background-repeat: none;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
}

.service-card{
    background-repeat: none;
    background-size: cover;
    background-image: url('../assets/img/card_bg.png');
    border-radius: 20px;
    padding:7%;
    padding-top: 17%;
    width: 540px !important;
    height: 500px;
    position: relative;
    margin-top: 17%;
    margin-right: 200px;
    margin-right: -10%;
}

.phone, .phone2, .phone3{
    display: none;
}
.img-card{
    padding-bottom: 7%;
}

.off{
    position: absolute;
    top: -12%;
    max-width: 78%;
}
 
.btn-inicio{
  padding: 2% 4% 2% 4%;
  margin-top: 4%;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.32px;
  text-align: left;
  border-radius: 100px;
  background-color: #0CCF8D;
  color: #191334;
  border: none;
}
 
 
.btn-inicio.b-button:focus,
.btn-inicio.b-button:hover,
.btn-inicio.b-button:active,
.btn-inicio.b-button:visited,
.btn-inicio.b-button:focus-visible,
.btn-inicio.b-button:focus-within,
.btn-inicio.b-button:focus:hover,
.btn-inicio.b-button:focus:active,
.btn-inicio.b-button:hover:focus,
.btn-inicio.b-button:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

.btn-inicio.btn:focus,
.btn-inicio.btn:hover,
.btn-inicio.btn:active,
.btn-inicio.btn:visited,
.btn-inicio.btn:focus-visible,
.btn-inicio.btn:focus-within,
.btn-inicio.btn:focus:hover,
.btn-inicio.btn:focus:active,
.btn-inicio.btn:hover:focus,
.btn-inicio.btn:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}


@media (max-width: 1370px) {
    .service-card{
        width: 500px !important;
        height: 500px;
    }

}
@media (max-width: 1266px) {
    .service-card{
        width: 450px !important;
        height: 500px;
    }

}

@media (max-width: 1140px) {
    .service-card{
        width: 400px !important;
        height: 500px;
    }

    .off{
        max-width: 85%;
    }

}@media (max-width: 1008px) {
    .phone{
        display: flex;
    }
    .service-card{
        width: 450px !important;
        height: 500px;
    }
    .desk{
        display: none;
    }

    .right{
        margin-left: -19%;
        margin-top: -2.5%;
    }
}@media (max-width: 980px) {
    .phone{
        display: flex;
    }
    .service-card{
        width: 400px !important;
        height: 500px;
    }
    .desk{
        display: none;
    }

    .right{
        margin-left: -19%;
        margin-top: -2.5%;
    }
}@media (max-width: 865px) {
    .phone{
        display: flex;
    }
    .service-card{
        width: 380px !important;
        height: 500px;
    }
    .desk{
        display: none;
    }

    .right{
        margin-left: -19%;
        margin-top: -2.5%;
    }
}@media (max-width: 815px) {
    .phone{
        display: none;
    }
 
    .desk{
        display: none;
    }
    .phone2{
        display: flex;
    }
    .service-card{
        width: 350px !important;
        height: 500px;
    }







 
}


@media (max-width:768px) {
  h3{
    font-size: 30px;
    line-height: 35px;
  }

  h4{
 
 font-size: 25px;
  
 line-height: 34.15px;
  
 
   } .img-card{
    width: 100px;
   }

   p, .btn-inicio{
        font-size: 16px;
line-height: 21.32px;
    }
    .service-card{
        width: 270px !important;
        height: 460px;
        margin-top: 25%;
    }
 
}

@media(max-width:500px){
   #soluciones{
    padding-top: 15%;
    padding-bottom: 15%;
   }
   .phone2{
        display: none;
    }

    .phone3{
      display: flex;
    }
   .img-card{
    width: 100px;
   }

   .service-card{
 
    padding:3%;
 
    width: 330px !important;
    height: 400px;
    position: relative;
    margin-top: 17%;
    margin-right: 200px;
    margin-right:  0%;
}

.right{
        margin-right: 0%;
        margin-top: 0%;
        margin-left: 0%;
    }
}

</style>