<template>
  <div class="m-0">
    <div id="menu" :class="[color, { 'navbar-expanded': isNavbarExpanded && color === 'light2' },{ 'navbar-expanded': isNavbarExpanded && color === 'dark' }]">
      <b-container>
      <b-row class="justify-content-center">
        <b-col>
          <b-navbar toggleable="lg" type="button">
            <b-navbar-brand href="#home">
              <a :href="$router.resolve({ name: 'home' }).href" @click="setActiveRoute('home')">
                <img :src="logo" id="logo" alt="logo" />
              </a>
            </b-navbar-brand>
            <b-navbar-toggle :class="color" target="nav-collapse" class="custom-toggler"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav v-model="isNavbarExpanded">
              <b-navbar-nav class="ml-auto">
                <b-nav-item
                  :href="$router.resolve({ name: 'home' }).href"
                  :class="{ active: isActive('home') }"
                  @click="setActiveRoute('home')"
                >
                  {{ translations['menu-nav'].home }}
                </b-nav-item>
                <b-nav-item
                  :href="$router.resolve({ name: 'NosotrosPage' }).href"
                  :class="{ active: isActive('NosotrosPage') }"
                  @click="setActiveRoute('NosotrosPage')"
                >
                  {{ translations['menu-nav'].aboutUs }}
                </b-nav-item>
                <b-nav-item
                  :href="$router.resolve({ name: 'SolucionesPage' }).href"
                  :class="{ active: isActive('SolucionesPage') }"
                  @click="setActiveRoute('SolucionesPage')"
                >
                  {{ translations['menu-nav'].solutions }}
                </b-nav-item>
                <b-nav-item
                  :href="$router.resolve({ name: 'ContactoPage' }).href"
                  :class="{ active: isActive('ContactoPage') }"
                  @click="setActiveRoute('ContactoPage')"
                >
                  {{ translations['menu-nav'].contact }}
                </b-nav-item>
                <b-nav-item>
                  <div class="idioma me-4">
                    <b-button
                      class="my-0 esp"
                      :class="{ active: activeLanguage === 'esp' }"
                      @click="setActiveLanguage('esp')"
                    >
                      ESP
                    </b-button>
                    <b-button
                      class="my-0 eng"
                      :class="{ active: activeLanguage === 'eng' }"
                      @click="setActiveLanguage('eng')"
                    >
                      ENG
                    </b-button>
                  </div>
                </b-nav-item>
                <b-row class="ml-1 compu">
                  <b-nav-item class="boton" href="https://www.facebook.com/FocusInvestigacion/" target="_blank">
                    <img class="icon" src="../assets/img//icn_facebook.svg" alt="facebook logo" />
                  </b-nav-item>
                  <b-nav-item class="boton" href="https://x.com/focus_bc" target="_blank">
                    <img class="icon" src="../assets/img//icn_twitter.svg" alt="twitter logo" />
                  </b-nav-item>
                </b-row>
                <b-row class="ml-1 phone justify-content-start">
                  <b-col>
                    <b-nav-item class="boton" href="https://www.facebook.com/FocusInvestigacion/" target="_blank">
                      <img src="../assets/img//icn_facebook.svg" alt="facebook logo" />
                    </b-nav-item>
                  </b-col>
                  <b-col>
                    <b-nav-item class="boton" href="https://x.com/focus_bc" target="_blank">
                    <img src="../assets/img//icn_twitter.svg" alt="twitter logo" />
                  </b-nav-item>

                  </b-col>
                   
                </b-row>
                 
 
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
      </b-container>
  </div></div>
</template>
<script>
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';

export default {
  props: {
    color: {
      type: String,
      default: 'dark',
    },
    logo: {
      type: String,
      default: require('../assets/img//img_logo_color.svg'),
    },
    activeRoute: {
      type: String,
      required: true,
    },
    activeLanguage: {
      type: String,
      required: true,
      default:'eng'
    }
  },
  data() {
    return {
      isNavbarExpanded: false,
    };
  },
  computed: {
    translations() {
      return this.activeLanguage === 'eng' ? en : esp;
    }
  },  watch: {
    activeLanguage(newLanguage) {
      this.translations = newLanguage === 'eng' ? en : esp;
    }
  },
  methods: {
 
    setActiveLanguage(language) {
      console.log('Setting active language:', language);
      this.$emit('update-language', language);
      localStorage.setItem('selectedLanguage', language);
    },
    isActive(routeName) {
      const isActive = this.activeRoute === routeName;
      console.log(`Checking if route ${routeName} is active:`, isActive);
      return isActive;
    },
    setActiveRoute(routeName) {
      console.log('Setting active route:', routeName);
      this.$emit('update-active-route', routeName);
    },
  },
  mounted() {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      console.log('Found saved language:', savedLanguage);
      this.$emit('update-language', savedLanguage);
    } else {
      console.log('No saved language found, setting default translations');
    }
  },
};
</script>
  
  <style scoped>
  .ml-auto {
    margin-left: auto;
  }
  
  a.nav-link {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.59px;
    text-align: center;
    color: #162b71;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
  }
  
  a.nav-link.active {
    font-weight: 700 !important;
  }
  
  #menu {
    padding-top: 1vh;
    padding-bottom: 1vh;
    position: fixed;
    max-width: 100%;
    z-index: 100;
    top: 0; 
    left: 0;
    width: 100%;
  } 
  #menu.dark {
    background:#1D33A2;
  }
  #menu.light {
    background-color: #d9e7f9;
  }
  #menu.light2 {
    background-color: transparent;
  }

  #menu.light2.navbar-expanded {
  background-color: #d9e7f9;
}
#menu.dark.navbar-expanded {
  background-color: #1D34A2;
}


  #menu.dark a.nav-link {
    color: #ffffff !important;
  }
  #menu.light a.nav-link,
  #menu.light2 a.nav-link {
    color: #162b71 !important;
  }
  #menu.light .idioma,
  #menu.light2 .idioma {
    border: 1px solid #162b71;
    width: 143px;
    height: 45px;
    border-radius: 100px;
    text-align: center;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
  }

  .inside{
    width: 90%;
  }

  .phone{
    display: none;
  }
  
  #menu.light .idioma .btn.active.esp,
  #menu.light2 .idioma .btn.active.esp {
    background-color: #162b71;
    color: #ffffff;
    width: 88px;
    height: 45px;
    border-radius: 100px;
  }
  
  #menu.light .idioma .btn.active.eng,
  #menu.light2 .idioma .btn.active.eng {
    background-color: #162b71;
    color: #ffffff;
    width: 83px;
    margin-right: -10px;
    height: 45px;
    border-radius: 100px;
  }
  
  #menu.light .idioma .btn.btn-secondary,
  #menu.light2 .idioma .btn.btn-secondary {
    background-color: transparent;
    border: none;
    color: #162b71;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.49px;
    text-align: center;
  }
  
  #menu.dark .idioma {
    border: 1px solid #ffffff;
    width: 143px;
    height: 45px;
    border-radius: 100px;
    text-align: center;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
  }
  
  #menu.dark .idioma .btn.active.esp {
    background-color: #ffffff;
    color: #162b71;
    width: 88px;
    height: 45px;
    border-radius: 100px;
  }
  
  #menu.dark .idioma .btn.active.eng {
    background-color: #ffffff;
    color: #162b71;
    width: 83px;
    margin-right: -10px;
    height: 43px;
    border-radius: 100px;
  }
  
  #menu.dark .idioma .btn.btn-secondary {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.49px;
    text-align: center;
  }
  @media (max-width: 1200px) {
    .compu{
      flex-wrap: nowrap;
    }
  }


  @media (max-width: 993px) {
    .phone{
      display: flex;
    }
    .compu{
      display: none;
    }

    .phone .col{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

}

@media(max-width:768px){
  #logo{
        width: 167px;
    }

    #menu.light2 {
    background-color:#d9e7f9;
  }
}


  </style>
  